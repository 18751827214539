import React, { useEffect } from "react"

import angleUp from "../../images/active-faq.svg"
import angleDown from "../../images/inactive-faq.svg"

import "./faqAccordion.scss"

export default function FaqAccordionItem(props) {
  const contentEl = React.useRef()

  const { index, title, isActive, description, toggleAccordion } = props

  useEffect(() => {
    if (isActive) {
      contentEl.current.style.height = `${contentEl.current.scrollHeight}px`
    } else {
      contentEl.current.style.height = "0px"
    }
  }, [isActive])

  return (
    <div className="wha-item">
      <button
        className="whai-title"
        onClick={() => toggleAccordion(title + index)}
      >
        <h5>{title}</h5>
        <div className={`whai-icon ${isActive ? "active" : ""}`}>
          {isActive ? (
            <img src={angleUp} alt="" className="img-fluid" />
          ) : (
            // <StaticImage
            //   src="../../images/active-faq.svg"
            //   alt=""
            //   className="img-fluid"
            // />
            <img src={angleDown} alt="" className="img-fluid" />
            // <StaticImage
            //   src="../../images/inactive-faq.svg"
            //   alt=""
            //   className="img-fluid"
            // />
          )}
        </div>
      </button>
      <div
        className={`whai-collapse  ${isActive ? "active-faq" : ""}`}
        ref={contentEl}
      >
        <div className={`whai-text`}>
          <span>{description}</span>
        </div>
      </div>
    </div>
  )
}
