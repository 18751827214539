import React from "react"
import FaqAccordionItem from "../../faq-accordion-item"

import "./faq.scss"

export default function NewFaqs({ data, active }) {
  const [activeAccordion, setActiveAccordion] = React.useState(
    active ? data?.[0]?.title + "0" : ""
  )
  const handleButtonClicked = newActive => {
    if (activeAccordion === newActive) {
      setActiveAccordion(null)
    } else {
      setActiveAccordion(newActive)
    }
  }

  return (
    <section className="wdn-new wdn-faqs mb-72px mb-lg-132px ">
      <div className="container">
        <div className="wf-inner-box py-32px px-32px py-lg-112px  px-lg-100px ">
          <div className="row">
            <div className="col-lg-4">
              <div className="section-header pb-40px pb-lg-0 ">
                <h2 className=" text-left">Frequently Asked Questions</h2>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="wh-accordion  pb-40px">
                {data?.map((item, index) => {
                  return (
                    <FaqAccordionItem
                      key={item.title + index.toString()}
                      index={index}
                      title={item.title}
                      isActive={
                        item.title + index.toString() === activeAccordion
                      }
                      description={item.description}
                      toggleAccordion={handleButtonClicked}
                      totalItem={data.length}
                    />
                  )
                })}
              </div>
              <div className="wf-more py-32px text-center mx-n32px mx-md-0">
                <h4>Still have questions?</h4>
                <span>
                  Can’t find the answer you’re looking for? <br /> Please
                  contact to our friendly team
                  <a href="mailto:hi@wdnexus.com "> hi@wdnexus.com </a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
