import React from "react"
import CandidateNewHome from "../../pages-sections/candidate-new-home"
import NewPageLayout from "../../components/layout/new-page-layout"

import candidateMeta from "../../images/candidateMeta.jpg"
export default function ForCandidate() {
  return (
    <NewPageLayout
      title=" WDN - Search Connect Work"
      isCandidate={true}
      description="Workers' Directory Nexus provides a simple and effective way to connect candidates with companies."
      seoImage={candidateMeta}
    >
      <CandidateNewHome />
    </NewPageLayout>
  )
}
